import type {EditorSDK} from '@wix/platform-editor-sdk'
import {SCHEDULE_SECTION_ID, EVENTS_SECTION_ID} from '@wix/wix-events-commons-statics'
import {EVENTS_APP_DEF_ID} from './constants'

export const getEventsPages = async (editorSDK: EditorSDK, appToken: string) => {
  const [{applicationId}, allPages] = await Promise.all([
    editorSDK.tpa.app.getDataByAppDefId(appToken, EVENTS_APP_DEF_ID),
    editorSDK.pages.data.getAll(appToken),
  ])

  return allPages.filter(({tpaApplicationId}) => tpaApplicationId === applicationId)
}

export const getSectionPageRef = async (sectionId: string, editorSDK: EditorSDK, appToken: string) => {
  const eventsPages = await getEventsPages(editorSDK, appToken)
  const {id, type} = eventsPages.find(({tpaPageId}) => tpaPageId === sectionId)
  return {id, type}
}

export const ensurePagesManaged = async (editorSDK: EditorSDK, appToken: string, t: Function) => {
  const eventsPages = await getEventsPages(editorSDK, appToken)

  const managedPageData: {[key: string]: {title: string}} = {
    [EVENTS_SECTION_ID]: {
      title: t('pagesPanelEventsDetailsTitle'),
    },
    [SCHEDULE_SECTION_ID]: {
      title: t('pagesPanelEventsScheduleTitle'),
    },
  }
  const managedPages = Object.keys(managedPageData)

  await Promise.all(
    eventsPages.map(async ({id, type, managingAppDefId: currentManagingAppDefId, tpaPageId}) => {
      if (currentManagingAppDefId !== EVENTS_APP_DEF_ID && managedPages.includes(tpaPageId)) {
        const data = {
          managingAppDefId: EVENTS_APP_DEF_ID,
          title: managedPageData[tpaPageId].title,
        }

        // @ts-expect-error
        await editorSDK.pages.data.update(appToken, {pageRef: {id, type}, data})
      }
      if (tpaPageId === SCHEDULE_SECTION_ID) {
        await editorSDK.document.pages.setState(appToken, {
          state: {
            [tpaPageId]: [{id}],
          },
        })
      }
    }),
  )
}
